import { NestedKeys } from "advanced-types";
import { Translation } from "../../assets/i18n/locals/en";
import { ICustomForm } from "../../models/CustomForm";
import {
  AdditionalDetails,
  AVAILABILITY,
  CONSERVATION_STATUS,
  FURNITURE_STATUS,
  HABITABILITY,
  HeatingDetails,
  HEATING_FUEL,
  HEATING_SYSTEM,
  HEATING_TYPE,
  QUALITY,
  RoomDetails,
  RoomPertinenceDetails,
  ROOM_PERTINENCE,
} from "../../models/RealEstate";
import { fillSelection } from "../../Utils/fuctions";
import "./selections";
import {
  availabilityType,
  boxTypes,
  countType,
  detailsConservationType,
  detailsFurnitureType,
  detailsHabitabilityType,
  detailsQualityType,
  dotationTypes,
  gardenTypes,
  heatingDetailsFuelType,
  heatingDetailsSystemType,
  heatingDetailsType,
  kitchenTypes,
  roomPertinenceTypes,
} from "./selections";

export interface IDescriptionForm {
  totalSquareMeters?: number;
  totalSquareMetersWalkable?: number;
  locals?: number;
  rooms: number;
  bathrooms: number;
  description: string;
  roomsPertinencesDetails: RoomPertinenceDetails[];
  otherRoomsDetails: RoomDetails[];
  heatingDetails: HeatingDetails;
  additionalDetails: AdditionalDetails;
}

export const initialValueDescriptionForm = {
  totalSquareMeters: 0,
  totalSquareMetersWalkable: 0,
  locals: 0,
  rooms: 0,
  bathrooms: 0,
  description: "",
  roomsPertinencesDetails: [],
  otherRoomsDetails: [],
  heatingDetails: {
    type: HEATING_TYPE.NOT_PROVIDED,
    fuel: HEATING_FUEL.NOT_PROVIDED,
    system: HEATING_SYSTEM.NOT_PROVIDED,
    radiators: 0,
    expenses: 0,
  },
  additionalDetails: {
    quality: QUALITY.NOT_PROVIDED,
    habitability: HABITABILITY.NOT_PROVIDED,
    conservationStatus: CONSERVATION_STATUS.NOT_PROVIDED,
    windows: 0,
    alarm: AVAILABILITY.NOT_PROVIDED,
    reception: AVAILABILITY.NOT_PROVIDED,
    intercom: AVAILABILITY.NOT_PROVIDED,
    furniture: FURNITURE_STATUS.NOT_PROVIDED,
    furnishedDescription: "",
    wardrobe: false,
    exposition: "",
  },
};

const prefixLabelCards = "realEstate.forms.description.cards";
const cards: {
  label: NestedKeys<Translation, ".">;
  value: ROOM_PERTINENCE;
  noDefaultValue?: boolean;
  isRequired?: boolean;
  secondaryKey: string;
  selectType: any;
}[] = [
  {
    label: `${prefixLabelCards}.balconies`,
    value: ROOM_PERTINENCE.BALCONIES,
    isRequired: true,
    secondaryKey: "type",
    selectType: roomPertinenceTypes,
  },
  {
    label: `${prefixLabelCards}.terrace`,
    value: ROOM_PERTINENCE.TERRACE,
    isRequired: true,
    secondaryKey: "type",
    selectType: roomPertinenceTypes,
  },
  {
    label: `${prefixLabelCards}.garden`,
    value: ROOM_PERTINENCE.GARDEN,
    isRequired: true,
    secondaryKey: "type",
    selectType: gardenTypes,
  },
  {
    label: `${prefixLabelCards}.garage`,
    value: ROOM_PERTINENCE.BOX,
    isRequired: true,
    secondaryKey: "type",
    selectType: boxTypes,
  },
  {
    label: `${prefixLabelCards}.terrain`,
    value: ROOM_PERTINENCE.TERRAIN,
    secondaryKey: "type",
    selectType: roomPertinenceTypes,
  },
  {
    label: `${prefixLabelCards}.coveredParkingSpaces`,
    value: ROOM_PERTINENCE.COVERED_PARKING_SPACES,
    secondaryKey: "count",
    noDefaultValue: false,
    selectType: countType(10),
  },
  {
    label: `${prefixLabelCards}.openParkingSpaces`,
    value: ROOM_PERTINENCE.OPEN_PARKING_SPACES,
    secondaryKey: "count",
    noDefaultValue: false,
    selectType: countType(10),
  },
  {
    label: `${prefixLabelCards}.bikeParkingSpaces`,
    value: ROOM_PERTINENCE.BIKE_PARKING_SPACES,
    secondaryKey: "count",
    noDefaultValue: false,
    selectType: countType(10),
  },
  {
    label: `${prefixLabelCards}.camperParkingSpaces`,
    value: ROOM_PERTINENCE.CAMPER_PARKING_SPACES,
    secondaryKey: "count",
    noDefaultValue: false,
    selectType: countType(10),
  },
  {
    label: `${prefixLabelCards}.boatParkingSpaces`,
    value: ROOM_PERTINENCE.BOAT_PARKING_SPACES,
    secondaryKey: "count",
    noDefaultValue: false,
    selectType: countType(10),
  },

  {
    label: `${prefixLabelCards}.cellar`,
    value: ROOM_PERTINENCE.CELLAR,
    secondaryKey: "type",
    selectType: roomPertinenceTypes,
  },
  {
    label: `${prefixLabelCards}.warehouse`,
    value: ROOM_PERTINENCE.WAREHOUSE,
    secondaryKey: "type",
    selectType: roomPertinenceTypes,
  },
  {
    label: `${prefixLabelCards}.depot`,
    value: ROOM_PERTINENCE.DEPOT,
    secondaryKey: "type",
    selectType: roomPertinenceTypes,
  },
  {
    label: `${prefixLabelCards}.loft`,
    value: ROOM_PERTINENCE.LOFT,
    secondaryKey: "type",
    selectType: roomPertinenceTypes,
  },
  {
    label: `${prefixLabelCards}.attic`,
    value: ROOM_PERTINENCE.ATTIC,
    secondaryKey: "type",
    selectType: roomPertinenceTypes,
  },
  {
    label: `${prefixLabelCards}.shed`,
    value: ROOM_PERTINENCE.SHED,
    secondaryKey: "type",
    selectType: roomPertinenceTypes,
  },
  {
    label: `${prefixLabelCards}.stable`,
    value: ROOM_PERTINENCE.STABLE,
    secondaryKey: "type",
    selectType: roomPertinenceTypes,
  },
];

export const roomPertinenceDetailsOptions: any = [
  // TODO remove any
  {
    name: {
      label: `${prefixLabelCards}.kitchen`,
      value: ROOM_PERTINENCE.KITCHEN,
    },
    type: fillSelection(kitchenTypes),
    dotations: fillSelection(dotationTypes),
  },
  ...cards.map(
    ({
      label,
      value,
      secondaryKey,
      selectType,
      noDefaultValue,
      isRequired,
    }) => ({
      name: { label, value, isRequired },
      [secondaryKey]: fillSelection(selectType, noDefaultValue),
    })
  ),
];

export const descriptionForm: ICustomForm<IDescriptionForm> = {
  title: {
    edit: "realEstate.forms.description.title",
    show: "realEstate.forms.description.title",
  },
  // description: {edit: "realEstate.forms.description.description.edit", show: "realEstate.forms.description.description.show"},
  // labelSubmitButton: {edit:"realEstate.forms.description.labelSubmit.edit", show: "realEstate.forms.description.labelSubmit.show"},
  labelSubmitButton: { edit: "editImage.save", show: "editImage.save" },
  next: "certifications",
  forms: [
    {
      title: "realEstate.forms.description.features.title",
      fields: [
        {
          label:
            "realEstate.forms.description.features.labels.totalSquareMeters",
          column: 2,
          isRequired: true,
          type: "number",
          key: "totalSquareMeters",
        },
        {
          label:
            "realEstate.forms.description.features.labels.totalSquareMetersWalkable",
          column: 2,
          maxColumn: 2,
          type: "number",
          key: "totalSquareMetersWalkable",
        },
        {
          label: "realEstate.forms.description.features.labels.locals",
          column: 2,
          isRequired: true,
          type: "number",
          key: "locals",
        },
        {
          label: "realEstate.forms.description.features.labels.rooms",
          column: 2,
          type: "number",
          key: "rooms",
          isRequired: true,
        },
        {
          label: "realEstate.forms.description.features.labels.bathrooms",
          column: 2,
          type: "number",
          key: "bathrooms",
          isRequired: true,
        },
        {
          label: "realEstate.forms.description.features.labels.description",
          column: 12,
          isRequired: true,
          type: "text-area",
          key: "description",
        },
      ],
    },
    {
      title: "realEstate.forms.description.deatils",
      fields: [
        {
          label: "",
          column: 12,
          type: "room-pertinence-details",
          key: "roomsPertinencesDetails",
          roomPertinenceDetailsOptions,
        },
      ],
    },
    {
      title: "realEstate.forms.description.otherRooms",
      fields: [
        {
          label: "",
          column: 12,
          type: "other-rooms-details",
          key: "otherRoomsDetails",
        },
      ],
    },
    {
      title: "realEstate.forms.description.heating.title",
      fields: [
        {
          label: "realEstate.forms.description.heating.labels.type",
          type: "select",
          isRequired: true,
          key: "heatingDetails/type",
          options: fillSelection(heatingDetailsType),
        },
        {
          label: "realEstate.forms.description.heating.labels.fuel",
          type: "select",
          key: "heatingDetails/fuel",
          options: fillSelection(heatingDetailsFuelType),
        },
        {
          label: "realEstate.forms.description.heating.labels.system",
          type: "select",
          key: "heatingDetails/system",
          options: fillSelection(heatingDetailsSystemType),
        },
        {
          label: "realEstate.forms.description.heating.labels.radiators",
          column: 2,
          maxColumn: 2,
          type: "number",
          key: "heatingDetails/radiators",
        },
        {
          label: "realEstate.forms.description.heating.labels.expenses",
          column: 2,
          type: "number",
          key: "heatingDetails/expenses",
        },
      ],
    },
    {
      title: "realEstate.forms.description.additionalFeatures.title",
      fields: [
        {
          label:
            "realEstate.forms.description.additionalFeatures.labels.quality",
          type: "select",
          column: 3,
          key: "additionalDetails/quality",
          options: fillSelection(detailsQualityType),
        },
        {
          label:
            "realEstate.forms.description.additionalFeatures.labels.habitability",
          type: "select",
          column: 3,
          key: "additionalDetails/habitability",
          options: fillSelection(detailsHabitabilityType),
        },
        {
          label:
            "realEstate.forms.description.additionalFeatures.labels.conservationsStatus",
          type: "select",
          isRequired: true,
          column: 3,
          key: "additionalDetails/conservationStatus",
          options: fillSelection(detailsConservationType),
        },
        {
          label:
            "realEstate.forms.description.additionalFeatures.labels.windows",
          column: 3,
          type: "number",
          key: "additionalDetails/windows",
        },
        {
          label: "realEstate.forms.description.additionalFeatures.labels.alarm",
          type: "select",
          column: 3,
          key: "additionalDetails/alarm",
          options: fillSelection(availabilityType),
        },
        {
          label:
            "realEstate.forms.description.additionalFeatures.labels.intercom",
          type: "select",
          column: 3,
          key: "additionalDetails/intercom",
          options: fillSelection(availabilityType),
        },
        {
          label:
            "realEstate.forms.description.additionalFeatures.labels.reception",
          type: "select",
          column: 3,
          maxColumn: 3,
          key: "additionalDetails/reception",
          options: fillSelection(availabilityType),
        },
        {
          label:
            "realEstate.forms.description.additionalFeatures.labels.furniture",
          type: "select",
          column: 3,
          key: "additionalDetails/furniture",
          options: fillSelection(detailsFurnitureType),
        },
        {
          label:
            "realEstate.forms.description.additionalFeatures.labels.furnishedDescription",
          type: "text-area",
          showIfKey: "additionalDetails/furniture",
          showIf: (furniture: FURNITURE_STATUS) =>
            furniture !== FURNITURE_STATUS.NOT_FURNISHED &&
            furniture !== FURNITURE_STATUS.NOT_PROVIDED,
          column: 12,
          key: "additionalDetails/furnishedDescription",
        },
        {
          label: "",
          type: "checkbox",
          column: 3,
          key: "additionalDetails/wardrobe",
          option: {
            label:
              "realEstate.forms.description.additionalFeatures.labels.wardrobe",
            value: false,
          },
        },
        {
          label:
            "realEstate.forms.description.additionalFeatures.labels.exposition",
          type: "text",
          column: 3,
          key: "additionalDetails/exposition",
        },
      ],
    },
  ],
};
