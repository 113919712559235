export const selectOptionsIt = {
  placeholderSelectedElements: "Selezionati",
  placeholderSelect: "Seleziona",
  demandStatus: {
    active: "Attiva",
    canceled: "Annullata",
    concluded: "Conclusa",
    lost: "Persa",
    expired: "Scaduta",
    suspended: "Sospesa",
  },
  furnitureStatus: {
    furnished: "Arredato",
    notFurnished: "Non Arredato",
    partiallyFurnisched: "Parzialmente Arredato",
    partiallyFurnishedKitchen: "Cucina parzialmente Arredata",
  },
  parking: {
    absent: "Assente",
    covered: "Coperto",
    open: "Aperto",
  },
  propertyOptions: {
    INDEPENDENTHOUSE: "Casa indipendente",
    INDEPENDENT_HOUSE: "Casa Indipendente",
    COMMERCIALOTHER: "Altro commerciale",
    COMMERCIAL_OTHER: "Altro commerciale",
    APPARTMENT: "Appartamento",
    ATTIC: "Attico/Mansarda",
    BASEMENT: "Seminterrato",
    CASTLE: "Castello",
    CHALET: "Baita/Chalet/Trullo",
    DUPLEX: "Duplex",
    FARM: "Fattoria",
    FARMHOUSE: "Cascina/Casale",
    HOUSE_SECTION: "Porzione di casa",
    HOUSESECTION: "Porzione di casa",
    LOFT: "Loft/Open Space",
    MULTI_PROPRIETY: "Multiproprietà",
    MULTIPROPRIETY: "Multiproprietà",
    NEW_CONSTRUCTION: "Nuove costruzioni",
    NEWCONSTRUCTION: "Nuove costruzioni",
    OTHER: "Altro",
    RESIDENCE: "Residence",
    ROOM: "Stanza",
    RUSTIC: "Rustico",
    SEMIDETACHEDVILLA: "Villa bifamiliare",
    SEMI_DETACHED_VILLA: "Villa bifamiliare",
    SEMIINDEPENDENTHOUSE: "Casa semindipendente",
    SEMI_INDEPENDENT_HOUSE: "Casa semindipendente",
    SINGLE_VILLA: "Villa singola",
    SINGLEVILLA: "Villa singola",
    TERRACED_HOUSE: "Villa a schiera",
    TERRACEDHOUSE: "Villa a schiera",
    VACATION_HOME: "Casa Vacanze",
    VACATIONHOME: "Casa Vacanze",
    VILLA: "Villa",
    VILLA_SECTION: "Porzione di Villa",
    VILLASECTION: "Porzione di Villa",
    NOTPROVIDED: "Non specificato",
    NOT_PROVIDED: "Non specificato",
    DANCECLUB: "Discoteca",
    NON_BUILDING_LAND: "Terreno non edificabile",
    NONBUILDINGLAND: "Terreno non edificabile",
    BUILDING_LAND: "Terreno edificabile",
    BUILDINGLAND: "Terreno edificabile",
    AGRICULTURAL_LAND: "Terreno agricolo",
    AGRICULTURALLAND: "Terreno agricolo",
    COMMERCIAL_LAND: "Terreno commerciale",
    COMMERCIALLAND: "Terreno commerciale",
  },
  propertyOptionsCommercial: {
    FARMHOUSE: "Agriturismo",
    HOTEL: "Hotel",
    COMMERCIAL_ACTIVITY: "Attività commerciale",
    COMMERCIALACTIVITY: "Attività commerciale",
    FARM: "Azienda Agricola",
    BAR: "Bar",
    CAMPING_AREA: "Area Campeggio",
    CAMPINGAREA: "Area Campeggio",
    CHALET: "Chalet",
    COMMERCIAL_OTHER: "Altro commerciale",
    COMMERCIALOTHER: "Altro commerciale",
    DANCE_CLUB: "Discoteca",
    DANCECLUB: "Discoteca",
    LABORATORY: "Laboratorio",
    COMMERCIALLOCAL: "Locale Commerciale",
    COMMERCIAL_LOCAL: "Locale Commerciale",
    STORE: "Negozio",
    RESTAURANT: "Ristorante",
    SHOW_ROOM: "Show Room",
    SHOWROOM: "Show Room",
    BUILDING: "Stabile / Palazzo",
    OFFICE: "Ufficio",
    NOT_PROVIDED: "Non fornito",
    NOTPROVIDED: "Non fornito",
    DEPOSIT: "Deposito",
  },
  propertyOptionsLand: {
    NON_BUILDING_LAND: "Terreno Non Edificabile",
    BUILDING_LAND: "Terreno Edificabile",
    AGRICULTURAL_LAND: "Terreno Agricolo",
    COMMERCIAL_LAND: "Terreno Commerciale",
  },
  currentState: {
    UNDER_CONSTRUCTION: "In Costruzione",
    FREE_TO_DEED: "Libero al rogito",
    LEASED: "Dato in locazione",
  },
  contractOptions: {
    RENT: "Affitto",
    SALE: "Vendita",
  },
  energyClass: {
    A1: "A1",
    A2: "A2",
    A3: "A3",
    A4: "A4",
    B: "B",
    C: "C",
    D: "D",
    E: "E",
    F: "F",
    G: "G",
    NOT_AVAILABLE: "Non Disponibile",
  },
  energyClassOld: {
    A_PLUS: "A",
    B: "B",
    C: "C",
    D: "D",
    E: "E",
    F: "F",
    G: "G",
    EVALUATED: "Valutata",
    NOT_APPLICABLE: "Non Applicabile",
    NOT_DECLARED: "Non dichiarata",
  },
  performanceUnit: {
    SQUARE_METERS: "Metri Quadri",
    CUBIC_METERS: "Metri Cubi",
  },
  energyPerformance: {
    LOW: "Basse",
    MEDIUM: "Medie",
    HIGH: "Alte",
  },
  soundCLass: {
    A: "A",
    B: "B",
    C: "C",
    D: "D",
  },
  unit: {
    SQUARE_METERS: "Metri Quadrati",
    CUBIC__METERS: "Metri Cubi",
  },
  state: {
    ACTIVE: "Attivo",
    REMOVED_FROM_MARKET: "Rimosso dal mercato",
  },
  mediation: {
    EXCLUSIVE: "In esclusiva",
    NOT_EXCLUSIVE: "Non in esclusiva",
  },
  roomSeparator: {
    WALLS: "Muri",
    OPEN_SPACE: "Spazio ",
    PANELS: "Pannelli",
  },
  fee: {
    YEARLY: "Annuale",
    BIMONTHLY: "Bimestrale",
    MONTHLY: "Mensile",
    WEEKLY: "Settimanale",
    SEASONAL: "Stagionale",
  },
  context: {
    IN_MALL: "In centro commerciale",
    OFFICE_COMPLEX: "Complesso edifici",
    MEZZANINE: "Soppalco",
    STREET: "Sulla strada",
    OTHER: "Altro",
  },
  weeklyRest: {
    SATURDAY: "Sabato",
    SUNDAY: "Domenica",
  },
  landKind: {
    FLAT: "Pianeggiante",
    STEEP: "Scosceso",
  },
  detailsFurnitureType: {
    NOT_FURNISHED: "Non Arredato",
    FURNISHED: "Arredato",
    PARTIALLY_FURNISHED: "Parzialmente Arredato",
    PARTIALLY_FURNISHED_KITCHEN: " Parzialmente arredato di cucina",
  },
  availabilityType: {
    PRESENT: "Presente",
    ABSENT: "Assente",
  },
  detailsConservationType: {
    GOOD: "Buono",
    NEEDS_CLEANING: "Da Pulire",
    NEEDS_RESTRUCTURING: "Da Ristrutturare",
    DECENT: "Discreto",
    ROUGH: "Grezzo",
    MEDIOCRE: "Mediocre",
    GREAT: "Ottimo",
    RESTRUCTURED: "Ristrutturato",
  },
  detailsHabitabilityType: {
    IMMEDIATE: "Abitabilità immediata",
    NOT_HABITABLE: "Non abitabile",
  },
  rating: {
    STANDARD: "Standard",
    LOW: "BAsso",
    HIGH: "Alto",
    EXCELLENT: "Eccellente",
  },
  rentDetailsRequestedWarranty: {
    NONE: "Nessuna",
    SECURITY_DEPOSIT: "Deposito cauzionale",
    INSURANCE_POLICY: "Polizza Assicurativa",
    BANK_GUARANTEE: "Fideiussione bancaria",
  },
  rentDetailsContractType: {
    THREE_PLUS_TWO: "3+2",
    THREE_PLUS_TWO_TAX: "3+2 Tax",
    FOUR_PLUS_TWO: "4+2",
    FOUR_PLUS_FOUR: "4+4",
    FOUR_PLUS_FOUR_TAX: "4+4 Tax",
    SIX_PLUS_TWO: "6+2",
    SIX_PLUS_SIX: "6+6",
    ROOM: "Stanza",
    TRANSITIONAL: "Transitorio",
    TRANSITIONAL_TAX: "Transitorio Cedolare",
    NOT_PROVIDED: "Non Specificato",
  },
  floorOptions: {
    ATTIC: "Attico",
    BASEMENT: "Seminterrato",
    BUILDING: "Edificio",
    EARTH_RETAINING_WALL: "Controterra",
    GROUND_FLOOR: "Piano terra",
    MULTISTOREY: "Multipiano",
    NOBLE_FLOOR: "Piano nobile",
    TOP_FLOOR: "Ultimo piano",
    MEZZANINE: "Piano rialzato",
    ON_TWO_LEVELS: "Su due livelli",
  },
  elevatorOprions: {
    YES: "Si",
    NO: "No",
  },
  garden: {
    ABSENT: "Assente",
    IN_COMMON: "Comune",
    PRIVATE: "Privato",
  },
  kitchen: {
    AT_SIGHT: "A vista",
    HABITABLE: "Abitabile",
    KITCHENETTE: "Angolo cottura / Cucinotto",
    ABSENT: "Assente",
    FOOD_COOK: "Cuocivivande",
    PRESENT: "Presente",
    SEMI_HABITABLE: "Semiabitabile",
  },
  box: {
    SINGLE: "Singolo",
    DOUBLE: "Doppio",
    TRIPLE: "Triplo",
    QUADRUPLE: "Quadruplo",
    SHED: "Rimessa",
    ABSENT: "Assente",
  },
  dotation: {
    EQUIPPED_AND_FURNISHED: "Arredata ed equipaggiata",
    EQUIPPED_NOT_FURNISHED: "Equipaggiata ma non arredata",
    NOT_EQUIPPED_FURNISHED: "Non equipaggiata e non arredata",
  },
  roomPertinence: {
    ABSENT: "Assente",
    PRESENT: "Presente",
  },
  architecturalBarriers: {
    ABSENT: "Assenti",
    PRESENT: "Presenti",
  },
  position: {
    AGRICULTURAL_AREA: "Zona agricola",
    BOOMING_AREA: "Zona in forte espansione",
    CENTRAL: "Centrale",
    HIGH_TRAFFIC_STREET: "Strada alto traffico",
    INDUSTRIAL_AREA: "Zona industriale",
    ISOLATED: "Isolata",
    MALL: "Centro commerciale",
    MIXED_AREA: "Zona mista",
    PEEP_AREA: "Zona peep",
    PERIPHERAL: "Periferica",
    RESIDENTIAL_AREA: "Zona residenziale",
    SEAFRONT: "Lungomare",
    SEMICENTRAL: "Semicentrale",
    SERVED_AREA: "Zona servita",
    TOURIST: "Zona turistica",
  },
  detailsQualityType: {
    CLASSIC: "Classico signorile",
    VINTAGE: "Epoca",
    MODERN_MEDIUM: "Moderno medio",
    MODERN_CLASSIC: "Moderno signorile",
    POPULAR: "Popolare",
    CLASSIC_ELEGANT: "Signorile",
    HISTORIC: "Storico",
  },
  heatingDetailsSystemType: {
    RADIATOR: "A radiatori / pannelli radianti",
    FLOOR: "A pavimento / battiscopa",
    AIR: "Ad aria",
    HEATER: "A stufa",
  },
  heatingDetailsType: {
    AUTONOMOUS: "Autonomo",
    CENTRALIZED: "Centralizzato",
    CENTRALIZED_WITH_METER: "Centralizzato con contabilizzatore di calore",
    AIR_CONDITIONED: "Climatizzato",
    ABSENT: "Assente",
  },
  heatingDetailsFuelType: {
    METHANE: "Metano",
    DIESEL: "Gasolio",
    GPL: "GPL",
    SOLAR_PANELS: "Pannelli fotovoltaici",
    AIR_ELECTRIC: "Aria / Elettrico",
    PELLET: "Pellet",
    WOOD: "Legna",
    WOOD_CHIPS: "Cippato",
    OTHER: "Altro",
  },
  additionalRooms: {
    ROOF_TERRACE: "Altana",
    OTHER: "Altro",
    AMERICAN_ENSUITE: "American Ensuite",
    ANTEROOM: "Anticamera",
    WARDROBE: "Armadio/Guardaroba",
    DRYING_ROOM: "Asciugatoio",
    BATHROOM: "Bagno",
    BALCONY: "Balcone",
    BAR: "Bar",
    BOX: "Box",
    KIDS_ROOM: "Camera bimbi",
    ROOM_WITH_BATHROOM: "Camera con bagno",
    BEDROOM: "Camera da letto",
    SECURITY_ROOM: "Camera di sicurezza",
    FAMILY_ROOM: "Camera familiare",
    DOUBLE_ROOM: "Camera matrimoniale",
    CELLAR: "Cantina",
    CORRIDOR: "Corridoio",
    KITCHEN: "Cucina",
    HABITABLE_KITCHEN: "Cucina abitabile",
    GAMES_ROOM: "Games room",
    GARDEN: "Giardino",
    WINTER_GARDEN: "Giardino d'inverno",
    ENTRANCE: "Ingresso",
    LABORATORY: "Laboratorio",
    LAUNDRY: "Lavanderia",
    WOODSHED: "Legnaia/Carbonaia",
    LIBRARY: "Libreria/Biblioteca",
    TOOL_ROOM: "Locali attrezzi",
    BOILER_ROOM: "Locale caldaia",
    TANK_ROOM: "Locale cisterna",
    SHOWER_ROOM: "Locale doccia",
    LOFT: "Loft",
    LODGE: "Loggia",
    LUNCH_ROOM: "Sala da pranzo",
    WAREHOUSE: "Magazzino",
    MAID_ROOM: "Camera di servizio",
    GYM: "Palestra",
    PARTY_ROOM: "Stanza da feste",
    PARKING_PLACE: "Parcheggio",
    PRIVATE_ROOM: "Stanza privata",
    RESTING_ROOM: "Stanza di riposo",
    CLOSET: "Ripostiglio",
    LIVING_ROOM: "Soggiorno",
    DOUBLE_LIVING_ROOM: "Salone doppio",
    LOUNGE: "Salotto",
    SAUNA: "Sauna",
    GREEN_HOUSE: "Serra",
    SOLARIUM: "Solarium",
    L_ROOM: "Stanza a L",
    T_ROOM: "Stanza a T",
    U_ROOM: "Stanza a U",
    Z_ROOM: "Stanza a Z",
    STUDIO: "Studio",
    TERRACE: "Terrazzo",
    DINING_ROOM: "Tinello",
    TOILETTE: "Toilette",
    TOY_ROOM: "Stanza dei giochi",
    GENERAL: "Generale",
    PLANIMETRY: "Planimetria",
  },
  rentDetailsContractRegistration: {
    OWNER: "A carico del proprietario",
    AGENCY: "A carico dell'agenzia",
  },
  otherInfoLabel: {
    AIR_CONDITIONING: "Aria condizionata",
    AIR_CONDITIONING_TYPE: {
      ABSENT: "Assente",
      AUTONOMOUS: "Autonoma",
      CENTRALIZED: "Centralizzata",
      PREDISPOSITION: "Predisposizione",
      NOT_PROVIDED: "Non specificata",
    },
    SHOWER: "Doccia",
    ALUMINIUM_FIXTURES: "Infissi in Alluminio",
    PARQUET: "Parquet",
    CLOSET: "Ripostiglio",
    JACUZZI: "Vasca idromassaggio",
    POOL: "Piscina",
    CHIMNEY: "Camino",
    AT_AUCTION: "All'asta",
    WOOD_FIXTURES: "Infissi in legno",
    SHUTTERS: "Persiane / Tapparelle",
    SUSPENDED_SANITARY: "Sanitari sospesi",
    LAKE_VIEW: "Vista Lago",
    TERRACE: "Terrazza",
    ALARM: "Allarme",
    ANTIFIRE: "Antincendio",
    REFRIGERATOR: "Refrigeratore",
    GENERATOR: "Gruppo elettrogeno di continuità",
    SAFETY_BOX: "Cassetta di sicurezza",
    INDEPENDENT_ENTRANCE: "Entrata indipendente",
    ALARM_PREDISPOSED: "Predisposizione allarme ",
    SEA_VIEW: "Vista Mare",
    PORCH: "Veranda",
    PHONE_SYSTEM: "Impianto telefonico",
    TV_ANTENNA: "Antenna TV ",
    TV_STATUS: {
      UNPROVIDED: "Sprovvisto",
      CONDOMINIUM: "Condominiale",
      AUTONOMOUS: "Autonomo",
    },
    ELECTRICAL_SYSTEM: "Impianto Elettrico",
    ELECTRICAL_SYSTEM_STATUS: {
      ABSENT: "Assente",
      LEGAL: "A norma",
      NOT_LEGAL: "No a norma",
    },
    SAT_TV: "TV Sat",
    WATER_PIPES: "Tubature acqua",
    FACING_STREET: "Fronte strada",
    PHONE_LINES: "Linee telefoniche",
    DIVISIBLE_LOT: "Lotto frazionabile",
    METHANE: "Metano",
  },
  REAL_ESTATE_STATE: {
    ACTIVE: "Attivo",
    REMOVED_FROM_MARKET: "Rimosso dal mercato",
  },
  APPROVAL_STATUS: {
    DRAFT: "Bozza",
    APPROVED: "Attivo",
    PENDING: "In pre-attivazione",
    PUBLISHED: "Attivo",
    SOLD: "Venduto",
    SUSPENDED: "Sospeso",
    SOLDSUSPENDED: "Venduto con sospensiva ",
  },
};
